'use client';
import React, { useEffect } from 'react';

export default function NotFound() {
  useEffect(() => {
    window.location = '/';
  }, []);

  return (
    <div className="w-full h-full text-2xl text-grey-7 flex items-center justify-center bg-site-background">
      Redirecting...
    </div>
  );
}
